<template>

  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>

    <view-intro class="text-start" heading="Beschikbare gezet tijdsblokken"/>


    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="mb-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
        <v-btn
          class="ml-4"
          elevation="2"
          icon
          color="orange"
          outlined
          @click="createNewBock.dialog = true"
        >
          <i class="fa-regular fa-plus fa-xl"></i>
        </v-btn>
      </div>
      <div class="mt-8 d-flex align-items-center justify-content-between">
        <v-btn
          elevation="2"
          color="blue"
          outlined
          @click="changeWeek(-7)"
        >
          <i class="fa-regular fa-arrow-left fa-xl"></i>
        </v-btn>
        <p class="h3 mb-0">
          Week {{ selection.weekDate }}
        </p>
        <v-btn
          elevation="2"
          color="blue"
          outlined
          @click="changeWeek(7)"
        >
          <i class="fa-regular fa-arrow-right fa-xl"></i>
        </v-btn>
      </div>
      <div v-if="timeBlocks.length">
        <div style="cursor: pointer;" v-for="timeBlock in timeBlocks" :key="timeBlock.id"
             @click="goToTimeBlock(timeBlock)"
             class="mt-5 py-4 px-3 elevation-3 rounded d-flex justify-content-between"
        >
          <p class="mb-0">
            {{
              getDay(timeBlock.start)
            }} {{
              timeBlock.type == 'once' ?
                '(' + new Date(timeBlock.start.seconds * 1000).toISOString().slice(0, 10).split('-').reverse().join('-') + ')' : ''
            }}
            <v-chip
              v-if="timeBlock.type === 'weekly'"
              color="primary"
              class="ml-1"
            >
              <i class="fa-regular fa-rotate-left mr-2"></i>
              Elke week
<!--              ({{-->
<!--                new Date(timeBlock.start.seconds * 1000).toISOString().slice(5, 10).split('-').reverse().join('-')-->
<!--              }})-->
            </v-chip>
            <v-chip
              v-if="timeBlock.status === 'removed'"
              color="red"
              outlined
              class="ml-1"
            >
              <i class="fa-regular fa-trash-alt mr-2"></i>
              Verwijderd
            </v-chip>

          <p class="mb-0 text-muted">
            {{ formatTime(timeBlock.start) }} - {{ formatTime(timeBlock.end) }}
          </p>
        </div>
        <div class="mt-4">
          <v-btn
            color="primary"
            elevation="2"
            outlined
            @click="$router.go(-1)"
          >&lArr; Terug
          </v-btn>
        </div>
      </div>
      <div v-else>
        <p class="text-muted text-center mt-10 h6">
          Er zijn geen bezgibare tijden govenden voor dit week!
        </p>
      </div>
    </div>
    <v-dialog
      v-model="createNewBock.dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title calss="d-flex">
          <span class="text-h3">Beschikbaar tijd toevoegen</span>
        </v-card-title>
        <div style="gap: 10px; max-width: 90%; margin: 0 auto" class="d-flex flex-column">
          <vc-date-picker
            class="mt-2 mb-2"
            style="width: 100%;"
            mode="dateTime"
            v-model="model"
            :min-date="new Date()"
            :validHours="isHourValid"
            :minute-increment="15"
            :masks="masks"
            is24hr
            is-range
          />
          <v-checkbox
            class="mt-0"
            v-model="model.weeklySchedule"
            :label="`Elke ${getStartDay()} toevoegen?`"
          ></v-checkbox>
        </div>
        <v-alert
          dense
          outlined
          text
          class="ml-4 mr-4"
          v-if="createNewBock.dialogAlert"
          :type="createNewBock.dialogStatus"
        >{{ createNewBock.dialogAlert }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >Annuleren
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createNewTimeBlock"
            :loading="createNewBock.loading"
          >Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

  export default {
    name: 'TimeBlock',
    data () {

      return {
        selectionStartDate: this.getSelectionStartDate(),
        selection: {
          from: null,
          to: null,
          weekDate: null,
        },
        allTimeBlocks: [],
        timeBlocks: [],
        model: {
          weeklySchedule: false,
          start: new Date(),
          end: new Date(new Date() + (1000 * 60 * 60 * 2)),
        },
        masks: {
          input: 'YYYY-MM-DD h:mm A',
        },
        createNewBock: {
          dialog: false,
          dialogAlert: '',
          dialogStatus: '',
          loading: false,
        },
        loading: true,
        alert: {
          visable: false,
          description: '',
        },
      }
    },
    mounted () {
      this.updateSelection()
      this.updateData()
    },
    methods: {
      updateData (){
        this.$auth.getTimeBlocks().then(timeBlocks => {
          this.allTimeBlocks = timeBlocks
          this.filterTimeBlocks()
        }).catch((err) => {
          this.alert.visable = true
          this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
        }).finally(() => {
          this.loading = false
        })
      },
      formatTime (timeBlockDate) {
        const date = new Date(timeBlockDate.seconds * 1000)
        const timeOtions = {
          hour: 'numeric',
          minute: 'numeric',
        }
        return date.toLocaleTimeString('nl-NL', timeOtions)
      },
      getDay (timeBlockDate) {
        const dateIndex = new Date(timeBlockDate.seconds * 1000).getDay()
        const dayArray = [
          'Zondag',
          'Maandag',
          'Dinsdag',
          'Woensdag',
          'Donderdag',
          'Vrijdag',
          'Zaterdag',
        ]
        return dayArray[dateIndex]
      },
      closeDialog () {
        this.createNewBock.dialog = false
        this.createNewBock.dialogAlert = ''
      },
      createNewTimeBlock () {
        const start = this.model.start.getTime()
        const end = this.model.end.getTime()
        if (start + (1000 * 60 * 59) >= end) {
          this.createNewBock.dialogStatus = 'error'
          this.createNewBock.dialogAlert = 'Uw selecteerde tijd is minder dan 60 minuten.'
          return
        }
        const timeBlock = {
          start: this.model.start,
          end: this.model.end,
          weeklySchedule: !!this.model.weeklySchedule,
        }
        this.createNewBock.loading = true
        this.$auth.createTimeBlock(timeBlock).then(() => {
          this.$swal({
            icon: 'success',
            title: 'Successvol opgeslagen',
            text: `${timeBlock.weeklySchedule ? 'Elke' : 'Dit'} week ${this.getStartDay()} van ${timeBlock.start.getHours() + ':' + timeBlock.start.getMinutes()} tot ${timeBlock.end.getHours() + ':' + timeBlock.end.getMinutes()}`,
          })
          this.createNewBock.dialogStatus = 'success'
          this.createNewBock.dialogAlert = `Uw block is succesvol toegevoegd!`
          this.updateData()
        }).catch(err => {
          this.createNewBock.dialogStatus = 'error'
          this.createNewBock.dialogAlert = `Er is iets mis gegaan: ${err.message}. Neem contact op met beheerder`
        }).finally(() => {
          this.createNewBock.loading = false
        })
      },
      getStartDay () {
        const dayIndex = this.model.start.getDay()
        const dayArray = [
          'zondag',
          'maandag',
          'dinsdag',
          'woensdag',
          'donderdag',
          'vrijdag',
          'zaterdag',
        ]
        return dayArray[dayIndex]
      },
      isHourValid (hourThatIsSelected) {
        if(this.model.start.getTime()> new Date().getTime()){
          return true
        }
        let startDateVal = new Date().getHours()
        return hourThatIsSelected >= startDateVal
      },
      goToTimeBlock (timeBlock) {
        const { id } = timeBlock
        this.$router.push(`/timeblock/${id}`)
      },
      getSelectionStartDate () {
        const date = new Date()
        const first = date.getDate() - date.getDay() + 1
        const sixth = first

        const monday = new Date(date.setDate(sixth))
        return monday
      },
      getFrom () {
        const date = new Date(this.selectionStartDate)
        const first = date.getDate() - date.getDay() + 1
        const sixth = first

        const monday = new Date(date.setDate(sixth))
        return monday
      },
      getTo () {
        const today = new Date(this.selectionStartDate)
        const first = today.getDate() - today.getDay() + 1
        const sixth = first + 6

        const sunday = new Date(today.setDate(sixth))
        return sunday
      },
      getCurrentWeekDate () {
        const currentDate = new Date(this.selectionStartDate)
        const startDate = new Date(currentDate.getFullYear(), 0, 1)
        const days = Math.floor((currentDate - startDate) /
          (24 * 60 * 60 * 1000))
        const weekNumber = Math.ceil(days / 7)
        const weekDate = `${weekNumber} (${currentDate.getFullYear()})`
        return weekDate
      },
      changeWeek (number) {
        const date = new Date(this.selectionStartDate)
        date.setDate(date.getDate() + number)

        this.selectionStartDate = date
        this.updateSelection()
        this.filterTimeBlocks()
      },
      updateSelection () {
        this.selection.from = this.getFrom()
        this.selection.to = this.getTo()
        this.selection.weekDate = this.getCurrentWeekDate()
      },
      filterTimeBlocks () {
        const fromDate = this.getFrom().getTime()
        const toDate = this.getTo().getTime()
        const filteredTimeBlocks = this.allTimeBlocks.filter(timeBlock => {
          if (timeBlock.type === 'weekly' && fromDate > timeBlock.start.seconds * 1000 && (timeBlock.status === 'removed' ? timeBlock?.removedAt.seconds * 1000 > toDate: true)) return timeBlock
          if (timeBlock.start.seconds * 1000 > fromDate && timeBlock.end.seconds * 1000 < toDate) {
            return timeBlock
          }
        })
        this.timeBlocks = filteredTimeBlocks
      },
    },
  }
</script>

<style scoped>

</style>
